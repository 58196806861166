// @ts-nocheck
import { Button, ButtonGroup, Typography } from '@material-ui/core';
import MessageIcon from "@material-ui/icons/Message";
import ShareIcon from '@material-ui/icons/Share';
import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ABILITY_DAIRY_BILL, PERMISSION_PAY } from "../../../../_constants/permissions";
import { HasPermission } from '../../../common/AccessPermission';
import SubscriptionLock from '../../../common/FeatureFlag/SubscriptionLock';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import withLanguage from '../../../language/LanguageController';
import { get10DaysCycleDiff } from '../../../library/dateUtils';
import { currencyFormat, dateDiff } from '../../../library/util';
import CustomersSelect from "../../common/CustomersSelect";
import { DefaultLayout } from '../../common/Layout';
import InnerNav from '../InnerNav';
import AdvancePaymentModal from '../modalPopup/AdvancePaymentModal';
import BluetoothPrinter from '../modalPopup/BluetoothPrinter';
import CustomerBillMilkTotalModal from '../modalPopup/CustomerBillMilkTotalModal';
import CustomerMilkPaymentModal from '../modalPopup/CustomerMilkPaymentModal';
import CustomerMilkReceiveModal from '../modalPopup/CustomerMilkReceiveModal';
import ProductModal from '../modalPopup/ProductModal';
import BillDatePicker from "./BillDatePicker";
import PrintBillMenu from './CustomerAccount/PrintBillMenu';
import CustomerAccountController from './CustomerAccountController';

class CustomerAccount extends CustomerAccountController {

    getFixedComponents = () => {
        let { startDate, endDate, selectedCustomer, isBrowser, loan_balance, milk_payment_modal } = this.state;
        let { dairy_customer_id } = selectedCustomer || {};
        let { dairyId } = this.props;

        return (
            <>
                {milk_payment_modal && <CustomerMilkPaymentModal
                    t={this.props.t}
                    validator={this.milkPaymentValidator}
                    dueAmount={this.state.amount_to_pay}
                    loanBalance={loan_balance}
                    submitBtn={this.state.milk_payment_btn}
                    isOpen={this.state.milk_payment_modal}
                    onClose={this.toggleMilkPayModal}
                    onSubmit={this.milkBillPayment}
                />}
                {this.state.milk_receive_modal ? (
                    <CustomerMilkReceiveModal
                        t={this.props.t}
                        validator={this.milkReceiveValidator}
                        dueAmount={this.state.amount_to_receive}
                        submitBtn={this.state.milk_receive_btn}
                        isOpen={this.state.milk_receive_modal}
                        onClose={this.toggleMilkReceiveModal}
                        onSubmit={this.milkBillReceive} />
                ) : null}
                {this.state.product_modal && !isBrowser ? (
                    <ProductModal
                        t={this.props.t}
                        isOpen={this.state.product_modal}
                        onClose={this.toggleProductModal}
                        dairyId={dairyId}
                        dairyCustomerId={dairy_customer_id}
                        startDate={startDate}
                        endDate={endDate} />
                ) : null}
                {this.state.advance_payment_modal && !isBrowser ? (
                    <AdvancePaymentModal
                        t={this.props.t}
                        isOpen={this.state.advance_payment_modal}
                        onClose={this.toggleAdvancePaymentModal}
                        dairyId={dairyId}
                        dairyCustomerId={dairy_customer_id}
                        startDate={startDate}
                        endDate={endDate} />
                ) : null}
                {this.state.milk_total_modal && !isBrowser ? (
                    <CustomerBillMilkTotalModal
                        isOpen={this.state.milk_total_modal}
                        onClose={this.toggleMilkTotalModal}
                        dairyId={dairyId}
                        dairyCustomerId={dairy_customer_id}
                        startDate={startDate}
                        endDate={endDate} />
                ) : null}
                {this.state.isBSetting ? (
                    <BluetoothPrinter
                        t={this.props.t}
                        isOpen={this.state.isBSetting}
                        onClose={this.togglePrinterModel} />
                ) : null}
            </>
        );
    }

    render() {
        const {
            startDate,
            milk_amount,
            advance_payment,
            last_due,
            product_amount,
            payable_amount,
            net_payable,
            paid_amount,
            remaining,
            transferd_to_loan,
            cash_recovered,
            customer_type,
            amount_to_pay: amountToPay,
            amount_to_receive: amountToReceive,
            selectedCustomer,
            isAndroid,
            isFeatureSubscribed
        } = this.state;
        const { dairy_customer_id } = selectedCustomer || {};
        const { t, billingCycle, customers, header, bottomGutter } = this.props;

        const dDiff = dateDiff(moment(), startDate);
        const mDiff = billingCycle === "monthly" ? dDiff.months == 1 : get10DaysCycleDiff(startDate);
        const paymentButtons = (mDiff && (amountToPay > 0 || amountToReceive < 0) && customer_type === "seller") || (mDiff && amountToReceive > 0 && customer_type === "buyer")

        return (
            <DefaultLayout
                title={t("text_customers_bill")}
                toolbar={false}
                header={header}
                bottomGutter={bottomGutter}
                onDeviceChange={this.onDeviceChange}
                back>
                <div className="mb-2">
                    <div className="form-row">
                        <div className="col-3">
                            <div className="form-group mb-0">
                                <label htmlFor="customer_code" className="sr-only">{t("text_code")}</label>
                                <input
                                    className="form-control shadow-sm"
                                    type="number"
                                    id="customer_code"
                                    placeholder={t("text_code")}
                                    defaultValue={this.state.code}
                                    onChange={e => { e.persist(); this.handleCodeChange(e)}} />
                            </div>  
                        </div>
                        <div className="col">
                            <CustomersSelect
                                customers={customers}
                                value={selectedCustomer}
                                onChange={this.customerChangeHandler}
                                className="shadow-sm" />
                        </div>
                    </div>
                </div>
                <div className="t-card shadow-sm">
                    <div className="title right card__title card--material__title mt-0">
                        <div className="form-row justify-content-between flex-nowrap">
                            <div className="flex-grow-1">
                                <BillDatePicker
                                    billingCycle={billingCycle}
                                    onChange={this.onDateChange}
                                    isFeatureSubscribed={isFeatureSubscribed}
                                />
                            </div>
                            
                        </div>
                        <div className='m-2'>
                            <HasPermission ability={ABILITY_DAIRY_BILL} access={PERMISSION_PAY}>
                                {paymentButtons && <div className="d-flex justify-content-center">
                                    {amountToPay > 0 && customer_type === "seller" ? (
                                        <PrimaryButton size="large" className="px-3" onClick={this.toggleMilkPayModal}>
                                            {t("text_pay")}
                                        </PrimaryButton>
                                    ) : null}
                                    {(amountToReceive < 0 && customer_type === "seller")
                                        || (amountToReceive > 0 && customer_type === "buyer") ? (
                                        <PrimaryButton size="large" className="px-3" onClick={this.toggleMilkReceiveModal}>
                                            {t("text_receive")}
                                        </PrimaryButton>
                                    ) : null}
                                </div>}
                            </HasPermission>
                            </div>
                    </div>
                    <div className="content card__content card--material__content">
                        <div className="form-row justify-content-between align-items-center">
                            <div>
                                <Typography component="h2" variant="h6">{t("text_milk_bill")}</Typography>
                            </div>
                            <div>
                                <ButtonGroup size="small" aria-label="small outlined button group" variant="text">
                                    {isAndroid && selectedCustomer.c_mobile ? (
                                        <Button
                                            title="Send SMS"
                                            onClick={this.sendSmsMilkBill}
                                        >
                                            <MessageIcon fontSize="small" />
                                        </Button>
                                    ) : null}
                                    {isAndroid && <Button
                                        title="Share"
                                        onClick={this.shareMilkBill}
                                    >
                                        <ShareIcon fontSize="small" />
                                    </Button>}
                                    <PrintBillMenu onPrint={this.printMilkBill} />
                                </ButtonGroup>
                            </div>
                        </div>
                        <table className="table mb-2">
                            <tbody>
                                <tr>
                                    <td>
                                        {t("text_last_due")}
                                    </td>
                                    <td className="text-right">
                                        <span className="text-green">{last_due}</span>
                                    </td>
                                </tr>
                                <tr onClick={this.toggleMilkTotalModal}>
                                    <td className="border-top-blue2x">{t("text_milk_amount")}</td>
                                    <td className="border-top-blue2x text-right">{currencyFormat(milk_amount)}</td>
                                </tr>

                                <tr onClick={this.toggleProductModal}>
                                    <td>
                                        {t("text_product_purchase")}
                                    </td>
                                    <td className="text-right">
                                        <span className="text-red">{currencyFormat(product_amount)}</span>
                                    </td>
                                </tr>
                                <tr onClick={this.toggleAdvancePaymentModal}>
                                    <td>{t("text_advance")}</td>
                                    <td className="text-right">
                                        <span className="text-red">{currencyFormat(advance_payment)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{customer_type == "seller" ? t("text_payable_amount") : t("text_receivable")}</td>
                                    <td className="text-right">{currencyFormat(payable_amount)}</td>
                                </tr>
                                <tr>
                                    <td className="border-top-blue2x">{customer_type == "seller" ? t("text_net_payable") : t("text_net_receivable")}</td>
                                    <td className="text-right border-top-blue2x">{currencyFormat(net_payable)}</td>
                                </tr>
                                {transferd_to_loan != "0.00" ? (
                                    <tr>
                                        <td>{t("text_transferred_to_loan")}</td>
                                        <td className="text-right">{currencyFormat(transferd_to_loan)}</td>
                                    </tr>
                                ) : null}
                                {cash_recovered != "0.00" ? (
                                    <tr>
                                        <td>{customer_type == "seller" ? t("text_cash_recovered") : t("text_received_amount")}</td>
                                        <td className="text-right">
                                            <span className="text-green">{currencyFormat(cash_recovered)}</span>
                                        </td>
                                    </tr>
                                ) : null}
                                {paid_amount > 0 && (
                                    <tr>
                                        <td>{t("text_paid_amount")}</td>
                                        <td className="text-right">
                                            <span className={customer_type == "seller" ? "text-green" : "text-red"}>{currencyFormat(paid_amount)}</span>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        <div>
                                            {t("text_remaining_amount")}
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <span className="text-red">
                                            {currencyFormat(remaining)}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <InnerNav
                    menu="bill"
                    page="customerAccount"
                    navigator={this.props.navigator}
                    dairyCustomerId={dairy_customer_id} />
                {this.getFixedComponents()}
                {!isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

CustomerAccount.displayName = "CustomerAccount";

CustomerAccount.defaultProps = {
    header: true,
    bottomGutter: true
};

const mapStateToProps = state => {
    return {
        customers: state.dairy_customers.customers,
        dairyId: state.dairy.dairy.id,
        dairyName: state.dairy.dairy.name,
        billingCycle: state.dairy.dairy.billing_cycle
    }
}

export default connect(mapStateToProps)(
    withRouter(withLanguage(CustomerAccount))
);