// @ts-nocheck
// eslint-disable-next-line no-undef
const Firebase = (typeof FirebasePlugin != "undefined") ? FirebasePlugin : {
    logEvent: () => {},
    setScreenName: () => {},
    setUserId: () => {},
    setUserProperty: () => {}
};

/**
 * Log an event using Analytics:
 * @param {*} eventName name of event to log to Firebase Analytics
 * @param {*} properties  key/value object of custom event properties must be flat (non-nested) object
 * @returns Promise
 */
const logEvent = (eventName, properties = {}) => {
    console.log("logEvent", eventName, properties);
    return new Promise((resolve, reject) => {
        Firebase.logEvent(
            eventName,
            properties,
            success => resolve(success),
            error => reject(new Error(error))
        );
    });
};

/**
 * Set the name of the current screen in Analytics
 * @param {*} screenName name of screen to log to Firebase Analytics
 * @returns Promise
 */
const setScreenName = (screenName) => {
    console.log("setScreenName", screenName);
    return new Promise((resolve, reject) => {
        Firebase.setScreenName(
            {
                screenName: screenName
            },
            success => resolve(success),
            error => reject(new Error(error))
        );
    });
};

/**
 * Set a user id for use in Analytics
 * @param {*} userId name/id of user to set in Firebase Analytics
 * @returns Promise
 */
const setUserId = (userId) => {
    console.log("setUserId", userId);
    return new Promise((resolve, reject) => {
        Firebase.setUserId(
            userId,
            success => resolve(success),
            error => reject(new Error(error))
        );
    });
};

/**
 * Set a user property for use in Analytics
 * @param {string} name name of user property to set in Firebase Analytics
 * @param {string} value value of user property to set in Firebase Analytics
 * @returns Promise
 */
const setUserProperty = (name, value) => {
    console.log("setUserProperty", name, value);
    return new Promise((resolve, reject) => {
        Firebase.setUserProperty(
            name,
            value,
            success => resolve(success),
            error => reject(new Error(error))
        );
    });
};

const analytics = {
    logEvent,
    setScreenName,
    setUserId,
    setUserProperty
};

export default analytics;