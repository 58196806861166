
import React, { Fragment } from "react";
import { currencyFormat } from "../../../../library/util";
import PropTypes from "prop-types";
import { PrimaryButton } from "src/components/common/buttons/PrimaryButton";

const DairyBuyersReceivableGridView = ({
    t,
    monthDiff,
    filter,
    records,
    onReceiveClick,
    onPayClick,
    onCustomerClick
}) => {

    return (
        <>
            {records.map((row, index) => {
                if (filter && filter.length) {
                    filter = filter.toLowerCase();
                    const name = (row.customer_name || "").toLowerCase();
                    const code = (row.code || "").toLowerCase();
                    if (!name.includes(filter) && !code.includes(filter)) {
                        return null;
                    }
                }
                return (
                    <div className="t-card mb-2" key={row.dairy_customer_id} onClick={e => onCustomerClick(e, row.dairy_customer_id)}>
                        <div className={"content card__content card--material__content"}>
                            <div className="mb-7 clearfix">
                                <span className="capitalize">
                                    {`${row.code}-${row.customer_name}`}
                                </span>
                                {row.receivable_remaining > 0 && (
                                    <div className="float-right">
                                        <PrimaryButton
                                            variant="contained"
                                            size="small"
                                            disabled={!monthDiff}
                                            onClick={e => onReceiveClick(e, row, index)}
                                        >
                                            {t("text_receive")}
                                        </PrimaryButton>
                                    </div>
                                )}
                                {row.receivable_remaining < 0 && (
                                    <div className="float-right">
                                        <PrimaryButton
                                            variant="contained"
                                            size="small"
                                            disabled={!monthDiff}
                                            onClick={e => onPayClick(e, row, index)}
                                        >
                                            {t("text_pay")}
                                        </PrimaryButton>
                                    </div>
                                )}
                            </div>
                            <div>
                                <span className="lheight-28 text-red">{t("text_last_due")}: &nbsp;&nbsp;{currencyFormat(row.last_due)}</span>
                            </div>
                            <hr />
                            <div className="display-table">
                                <div className="display-table-cell text-left mr-2">
                                    {t("text_milk")}
                                    <span className="display-block text-red">
                                        {currencyFormat(row.milk_amount)}
                                    </span>
                                </div>
                                <div className="display-table-cell text-left mr-2">
                                    {t("text_product")}
                                    <span className="display-block text-red">
                                        {currencyFormat(row.product_amount)}
                                    </span>
                                </div>
                                <div className="display-table-cell text-left mr-2">
                                    {t("text_received")}
                                    <span className="text-green display-block">
                                        {currencyFormat(row.received)}
                                    </span>
                                </div>
                                <div className="display-table-cell text-right">
                                    {t("text_due")}
                                    <span className="text-red display-block">
                                        {currencyFormat(row.receivable_remaining)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

DairyBuyersReceivableGridView.propTypes = {
    t: PropTypes.func,
    monthDiff: PropTypes.bool,
    filter: PropTypes.string,
    records: PropTypes.array,
    onPayClick: PropTypes.func,
    onCustomerClick: PropTypes.func
};

export default DairyBuyersReceivableGridView;