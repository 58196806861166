import { InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import moment from "moment";
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SortButton from "src/components/core/SortingButton/SortButton";
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { DefaultScrollbar } from 'src/components/pages/common/Scrollbar';
import SubscriptionLock from '../../../../common/FeatureFlag/SubscriptionLock';
import { PrimaryTextField } from "../../../../core/TextField";
import withLanguage from '../../../../language/LanguageController';
import { get10DaysCycleDiff } from '../../../../library/dateUtils';
import { dateDiff } from '../../../../library/util';
import CustomerMilkPaymentModal from '../../modalPopup/CustomerMilkPaymentModal';
import CustomerMilkReceiveModal from '../../modalPopup/CustomerMilkReceiveModal';
import BillDatePicker from "../BillDatePicker";
import DairyBuyersBillController from './DairyBuyersBillController';
import DairyBuyersReceivableGridView from './DairyBuyersReceivableGridView';
import DairyBuyersReceivableListView from './DairyBuyersReceivableListView';

class DairyBuyersBill extends DairyBuyersBillController {
    getFixedComponents = e => {
        return (
            <Fragment>
                {this.state.milk_receive_modal ? (
                    <CustomerMilkReceiveModal
                        t={this.props.t}
                        validator={this.milkReceiveValidator}
                        dueAmount={this.state.amount_to_receive}
                        submitBtn={this.state.milk_receive_btn}
                        isOpen={this.state.milk_receive_modal}
                        onClose={this.toggleMilkReceiveModal}
                        onSubmit={this.milkBillReceive}/>
                ) : null}
                {this.state.milk_pay_modal ? (
                    <CustomerMilkPaymentModal
                        t={this.props.t}
                        validator={this.milkPayValidator}
                        dueAmount={Math.abs(this.state.amount_to_pay)}
                        submitBtn={this.state.milk_pay_btn}
                        isOpen={this.state.milk_pay_modal}
                        onClose={this.toggleMilkPayModal}
                        onSubmit={this.milkBillPay}/>
                ) : null}
            </Fragment>
        );
    }

    render() {
        const {
            records,
            page_view,
            filter,
            startDate,
            isBrowser,
            isFeatureSubscribed
        } = this.state;

        const { t, billingCycle, header } = this.props;
        
        const dDiff = dateDiff(moment(), startDate);
        const mDiff = billingCycle === "monthly" ? dDiff.months == 1 : get10DaysCycleDiff(startDate);
        const receivableRecords = this.sortBy(records).filter(record => parseFloat(record.receivable_remaining) != 0 );
        const receivedRecords = this.sortBy(records).filter(record => parseFloat(record.receivable_remaining) === 0 );
        return (
            <DefaultLayout 
                title={t("text_dairy_buyers_payment_details")}
                toolbar={false}
                header={header}
                bottomGutter={false}
                onDeviceChange={this.onDeviceChange}
                back>
                <div className="d-flex justify-content-between bg-white rounded shadow-sm mb-2 py-1 px-2">
                    <div className="float-left">
                        <BillDatePicker
                            value={startDate}
                            billingCycle={billingCycle}
                            onChange={this.onDateChange}
                        />
                    </div>
                    <div className="d-none d-lg-block">
                        <h3 className="h5 mb-0 mt-2 font-weight-bold">{t("text_buyers_bill")}</h3>
                    </div>
                    <div className="float-right">
                        <button className="btn" onClick={this.changeView}>
                            <i className={`fa ${page_view == "grid" ? "fa-table" : "fa-list"}`}/>
                        </button>
                    </div>
                </div>
                <form className="mb-2">
                    <div className="d-flex">
                        <div className="mr-2">
                            <SortButton 
                                selectedOption={this.state.sortSelectedOption}
                                onSortChange={this.handleSortingChange}
                            />
                        </div>
                        <PrimaryTextField
                            type="search"
                            id="search-bar"
                            placeholder={t("text_search")}
                            className="form-control border-0 bg-light"
                            onChange={this.filterHandler}
                            aria-label={t("text_search")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="start">
                                    <SearchIcon edge="end" />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </form>
                <DefaultScrollbar
                    isEnabled={isBrowser}
                    className={`dairy-billing-buyers-bill-scrollbar-${header}`}>
                    <div className="dairy_loan_type_slide">
                        {
                            page_view === 'grid'
                                ? <DairyBuyersReceivableGridView
                                    monthDiff={mDiff}
                                    t={t}
                                    filter={filter}
                                    records={[...receivableRecords, ...receivedRecords]}
                                    onReceiveClick={this.toggleMilkReceiveModal}
                                    onPayClick={this.toggleMilkPayModal}
                                    onCustomerClick={this.goToBill}
                                />
                                : <DairyBuyersReceivableListView
                                    t={t}
                                    filter={filter}
                                    records={[...receivableRecords, ...receivedRecords]}
                                    onCustomerClick={this.goToBill}
                                />
                        }
                    </div>
                </DefaultScrollbar>
                {this.getFixedComponents()}
                {!isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

DairyBuyersBill.displayName = "DairyBuyersBill";

DairyBuyersBill.defaultProps = {
    header: true
};

const mapStateToProps = state => {
    return {
        dairyId: state.dairy.dairy.id,
        billingCycle: state.dairy.dairy.billing_cycle
    }
}

export default connect(mapStateToProps)(
    withRouter(withLanguage(DairyBuyersBill))
);