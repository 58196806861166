// @ts-nocheck
import { InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import moment from "moment";
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Slider from 'react-slick';
import SortButton from "src/components/core/SortingButton/SortButton";
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { DefaultScrollbar } from "src/components/pages/common/Scrollbar";
import SubscriptionLock from '../../../../common/FeatureFlag/SubscriptionLock';
import { PrimaryTextField } from "../../../../core/TextField";
import withLanguage from '../../../../language/LanguageController';
import { get10DaysCycleDiff } from '../../../../library/dateUtils';
import { dateDiff } from '../../../../library/util';
import CustomerMilkPaymentModal from '../../modalPopup/CustomerMilkPaymentModal';
import CustomerMilkReceiveModal from '../../modalPopup/CustomerMilkReceiveModal';
import BillDatePicker from "../BillDatePicker";
import DairyPayableGridView from './DairyPayableGridView';
import DairyPayableListView from './DairyPayableListView';
import DairyReceivableGridView from './DairyReceivableGridView';
import DairyReceivableListView from './DairyReceivableListView';
import DairySellersBillController from './DairySellersBillController';

const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: false,
    touchMove: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
};
class DairySellersBill extends DairySellersBillController {

    getFixedComponents = e => {
        return (
            <Fragment>
                {this.state.milk_payment_modal ? (
                    <CustomerMilkPaymentModal
                        t={this.props.t}
                        validator={this.milkPaymentValidator}
                        dueAmount={this.state.amount_to_pay}
                        loanBalance={this.state.loan_balance}
                        submitBtn={this.state.milk_payment_btn}
                        isOpen={this.state.milk_payment_modal}
                        onClose={this.toggleMilkPayModal}
                        onSubmit={this.milkBillPayment}/>
                ) : null}
                {this.state.milk_receive_modal ? (
                    <CustomerMilkReceiveModal
                        t={this.props.t}
                        validator={this.milkReceiveValidator}
                        dueAmount={this.state.amount_to_receive}
                        submitBtn={this.state.milk_receive_btn}
                        isOpen={this.state.milk_receive_modal}
                        onClose={this.toggleMilkReceiveModal}
                        onSubmit={this.milkBillReceive} />
                ) : null}
            </Fragment>
        );
    }

    render() {
        const {
            payable_records,
            receivable_records,
            page_view,
            all_payable_bill_checked,
            is_payable_selected,
            milk_payment_btn,
            filter,
            startDate,
            isBrowser,
            isFeatureSubscribed,
            sortBy,
            sortOrder,
        } = this.state;
        const { t, billingCycle, header } = this.props;

        const dDiff = dateDiff(moment(), startDate);
        const mDiff = billingCycle === "monthly" ? dDiff.months == 1 : get10DaysCycleDiff(startDate);
        const payableRecordsExists = payable_records.length > 0;
        const receivableRecordsCountExists = receivable_records.length > 0;
        const payableRecords = this.sortBy(payable_records.filter(record => parseFloat(record.payable_remaining) != 0));
        const paidRecords = this.sortBy(payable_records.filter(record => parseFloat(record.payable_remaining) == 0));
        const receivableRecords = this.sortBy(receivable_records.filter(records=>parseFloat(records.receivable_remaining) != 0));
        const receivedRecords = this.sortBy(receivable_records.filter(records=>parseFloat(records.receivable_remaining) == 0));
        return (
            <DefaultLayout 
                title={t("text_dairy_seller_payment_detail")}
                toolbar={false}
                header={header}
                bottomGutter={false}
                onDeviceChange={this.onDeviceChange}
                back>
                <div className="d-flex justify-content-between bg-white rounded shadow-sm mb-2 py-1 px-2">
                    <div>
                        <BillDatePicker
                            value={startDate}
                            billingCycle={billingCycle}
                            onChange={this.onDateChange}
                        />
                    </div>
                    <div className="d-none d-lg-block">
                        <h3 className="h5 mb-0 mt-2 font-weight-bold">{t("text_sellers_bill")}</h3>
                    </div>
                    <div>
                        <button className="btn" onClick={this.changeView}>
                            <i className={`fa ${page_view == "grid" ? "fa-table" : "fa-list"}`}/>
                        </button>
                    </div>
                </div>
                <form className="mb-2 mb-lg-0">
                    <div className="d-flex">
                        <div className="mr-2">
                            <SortButton
                                className="btn btn-outline-secondary"
                                type="button"
                                id="button-addon1"
                                selectedOption={this.state.sortSelectedOption}
                                onSortChange={this.handleSortingChange}
                            />
                        </div>
                        <PrimaryTextField
                            type="search"
                            id="search-bar"
                            placeholder={t("text_search")}
                            className="form-control border-0 bg-light"
                            onChange={this.filterHandler}
                            aria-label={t("text_search")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="start">
                                    <SearchIcon edge="end" />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </form>
                <DefaultScrollbar
                    isEnabled={isBrowser}
                    className={`dairy-billing-sellers-bill-scrollbar-${header}`}>
                    <div className="dairy-billing-sellers-bill-slider">
                        <Slider className={`${(payableRecordsExists && receivableRecordsCountExists) ? "slick-dots-top" : "mt-2"} dairy_bill_statement`} {...sliderSettings}>
                            {
                                payableRecordsExists && ((page_view === "grid") 
                                    ? <DairyPayableGridView
                                        t={t}
                                        monthDiff={mDiff}
                                        filter={filter}
                                        records={[...payableRecords,...paidRecords]}
                                        payableSelected={is_payable_selected}
                                        paymentBtn={milk_payment_btn}
                                        allChecked={all_payable_bill_checked}
                                        onCheck={this.onPayableChecked}
                                        onAllCheck={this.onPayableAllChecked}
                                        onPayClick={this.toggleMilkPayModal}
                                        onAllPayClick={this.payDairyCustomersBill}
                                        onCustomerClick={this.goToBill} /> 
                                    : <DairyPayableListView
                                        t={t}
                                        filter={filter}
                                        records={[...payableRecords,...paidRecords]}
                                        onCustomerClick={this.goToBill} />)
                            }
                            {
                                receivableRecordsCountExists && ((page_view === "grid") 
                                    ? <DairyReceivableGridView
                                        t={t}
                                        monthDiff={mDiff}
                                        filter={filter}
                                        records={[...receivableRecords,...receivedRecords]}
                                        onPayClick={this.toggleMilkReceiveModal}
                                        onCustomerClick={this.goToBill} /> 
                                    : <DairyReceivableListView
                                        t={t}
                                        filter={filter}
                                        records={[...receivableRecords,...receivedRecords]}
                                        onCustomerClick={this.goToBill} />)
                            }
                        </Slider>
                    </div>
                </DefaultScrollbar>
                {this.getFixedComponents()}
                {!isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

DairySellersBill.displayName = "DairySellersBill";

DairySellersBill.defaultProps = {
    header: true
};

const mapStateToProps = state => {
    return {
        customers: state.dairy_customers.customers,
        dairyId: state.dairy.dairy.id,
        billingCycle: state.dairy.dairy.billing_cycle
    }
}

export default connect(mapStateToProps)(
    withRouter(withLanguage(DairySellersBill))
);