
import _ from 'lodash';
import moment from "moment";
import { Component } from 'react';
import { ORDER_ASC } from 'src/_constants';
import { checkFeatureInSubscription } from '../../../../common/FeatureFlag/FeatureFlag';
import SimpleValidator from '../../../../common/SimpleValidator';
import Eventer from '../../../../library/Eventer';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import { errorHandler } from '../../../../library/response';
import { loader, toast } from "../../../../library/util";

const dummyRecord = [
    {
        "is_payable": 1,
        "milk_amount": 1069.92,
        "last_due": "54.92",
        "product_amount": 1015,
        "paid": 0,
        "payable_remaining": "250.00",
        "transferd_to_loan": 0,
        "cash_recovered": 0,
        "receivable": 0,
        "received": 0,
        "receivable_remaining": 0,
        "dairy_id": "00001",
        "dairy_customer_id": "0001",
        "customer_name": "Dummy Customer",
        "code": "00"
    }
];

class BuyerPurchaseController extends Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.milkBillReceive = this.milkBillReceive.bind(this);
        this.milkBillPay = this.milkBillPay.bind(this);
        const locationStartDate = props?.location?.state?.startDate || null;
        const startDate = locationStartDate ? moment(locationStartDate) : moment();
        this.state = {
            milk_receive_modal: false,
            milk_pay_modal: false,
            amount_to_receive: 0,
            amount_to_pay: 0,
            dairy_customer_id: null,
            selected_index: null,
            milk_receive_btn: false,
            milk_pay_btn: false,
            page_view: 'grid',
            records: [],
            filter: '',
            isBrowser: false,
            isFeatureSubscribed: checkFeatureInSubscription("dairy_billing", false),
            sortSelectedOption: { field: 'customer_name', order: ORDER_ASC },
            ...getBillingStartEndDate(props.billingCycle, startDate)
        };
    }

    componentDidMount() {
        const sortedData = this.sortBy(this.state.records);
        this.setState({ data: sortedData });
        analytics.setScreenName("DairyBuyersBill");
        this.getData();
        Eventer.on("dairyBillDateChanged", this.dairyBillDateChanged);
    }

    componentWillUnmount() {
        Eventer.off("dairyBillDateChanged", this.dairyBillDateChanged);
    }

    dairyBillDateChanged = ({ detail }) => {
        this.onDateChange({
            startDate: detail.startDate,
            endDate: detail.endDate
        });
    }

    async getData() {
        if (!this.state.isFeatureSubscribed) {
            this.setState({
                records: dummyRecord
            });
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));
            let { startDate, endDate } = this.state;
            startDate = startDate.format('DD-MM-YYYY');
            endDate = endDate.format('DD-MM-YYYY');
            const filterStr = encodeURI(`start_date=${startDate}&end_date=${endDate}`);
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/buyers_bills?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                this.setState({
                    records: data
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    handleSortingChange = (selectedOption) => {
        this.setState({
            sortSelectedOption: selectedOption
        });
    }

    sortBy = (receivableRecords) => {
        let { field, order } = this.state.sortSelectedOption;
        field = field === "name" ? "customer_name" : field;
        
        const orderByField = rec => {
            if (field === 'code') {
                return parseInt(rec[field]);
            } else if (field === 'customer_name') {
                return rec[field].toLowerCase();
            }
            return rec[field];
        }; 
        const sortedRecords = _.orderBy(receivableRecords, [orderByField], [order]);
        
        return sortedRecords;
    }

    goToBill = (event, dairyCustomerId) => {
        event.persist();
        event.stopPropagation();
        if (event.target.type !== "checkbox") {
            if (this.state.isMobile) {
                this.props.history.push(`/dairy/bill/customer/${dairyCustomerId}`);
            }
        }
    }

    filterHandler = e => {
        this.setState({
            filter: e.target.value
        });
    }

    changeView = e => {
        analytics.logEvent("DairyBuyersBillChangeView");
        this.setState({
            page_view: this.state.page_view === "grid" ? 'list' : 'grid'
        })
    }

    onDateChange = ({ startDate, endDate }) => {
        if (!checkFeatureInSubscription("dairy_billing")) {
            return false;
        }
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getData();
        });
    }

    toggleMilkReceiveModal = (e, customer = {}, index = null) => {
        e.stopPropagation();
        e.preventDefault();
        if (!this.state.milk_receive_modal) {
            this.milkReceiveValidator = new SimpleValidator();
        }
        this.setState({
            milk_receive_modal: !this.state.milk_receive_modal,
            amount_to_receive: customer.receivable_remaining || 0,
            dairy_customer_id: customer.dairy_customer_id || 0,
            selected_index: index
        });
    }

    async milkBillReceive(e) {
        e.preventDefault();
        try {
            // validate form before submitting to server 
            if (!this.milkReceiveValidator.allValid()) {
                this.milkReceiveValidator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("receiveMilkBill");
            loader.show(this.props.t("text_loading"));

            const { endDate, dairy_customer_id } = this.state;
            this.setState({
                milk_receive_btn: true
            });

            const formData = new FormData(document.getElementById("milk_bill_receive_form"));
            formData.append('bill_date', endDate.format('DD-MM-YYYY'));

            const { status, message } = await request.post('/dairies/' + this.props.dairyId + `/bills/receive/${dairy_customer_id}`, {
                withAuth: true,
                body: formData,
                version: "v2"
            });
            if (status) {
                toast(this.props.t("text_customer_bill_updated_success"));
                this.setState({
                    milk_receive_modal: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillReceived");
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_receive_btn: false
            });
        }
    }

    toggleMilkPayModal = (e, customer = {}, index = null) => {
        e.stopPropagation();
        e.preventDefault();
        if (!this.state.milk_pay_modal) {
            this.milkPayValidator = new SimpleValidator();
        }
        this.setState({
            milk_pay_modal: !this.state.milk_pay_modal,
            amount_to_pay: customer.receivable_remaining || 0,
            dairy_customer_id: customer.dairy_customer_id || 0,
            selected_index: index
        });
    }

    async milkBillPay(e) {
        e.preventDefault();
        try {
            // validate form before submitting to server 
            if (!this.milkPayValidator.allValid()) {
                this.milkPayValidator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("milkBillPayment");
            loader.show(this.props.t("text_loading"));

            const { endDate, dairy_customer_id } = this.state;
            this.setState({
                milk_pay_btn: true
            });

            const formData = new FormData(document.getElementById("milk_bill_payment_form"));
            formData.append('bill_date', endDate.format('DD-MM-YYYY'));

            const { status, message } = await request.post('/dairies/' + this.props.dairyId + `/bills/pay/${dairy_customer_id}`, {
                withAuth: true,
                body: formData,
                version: "v2"
            });
            if (status) {
                toast(message);
                this.setState({
                    milk_pay_modal: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillPaid");
                });
            } else {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_pay_btn: false
            });
        }
    }

    onDeviceChange = deviceTypes => {
        this.setState({
            ...deviceTypes
        });
    }
}

export default BuyerPurchaseController;