// @ts-nocheck
import React from 'react';
import moment from "moment";
import { loader } from "../../../library/util";
import { errorHandler } from '../../../library/response';
import { getBillingStartEndDate } from '../../../library/dateUtils';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import { generatePDF } from "../../../library/pdfGenerator";
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';
import { isAndroid } from '../../../library/detect-device';

class DairyReportController extends React.Component {

    constructor(props) {
        super(props);
        this.generateReport = this.generateReport.bind(this);
        this.reportPreviewRef = React.createRef();
        let customers = [...props.customers];
        this.state = {
            customer_modal: false,
            customer_modal_btn: false,
            all_checked: true,
            customers: customers.map(customer => {
                let aCustomer = {...customer};
                aCustomer.is_checked = true;
                return aCustomer;
            }),
            is_loading: false,
            reportFilename: null,
            reportHtml: null,
            isFeatureSubscribed: checkFeatureInSubscription("reports", false),
            ...getBillingStartEndDate(props.billingCycle, moment())
        };
    }

    componentDidMount () {
        analytics.setScreenName("DairyReport");
        if(this.state.isFeatureSubscribed && !isAndroid()){
            this.generateReport();
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        });
    }

    toggleCustomerModal = () => {
        this.setState({
            customer_modal: !this.state.customer_modal
        })
    }

    onCustomerSelected = (dairyCustomerId, isChecked) => {
        const customers = [...this.state.customers];
        const customerIndex = customers.findIndex(customer => customer.dairy_customer_id == dairyCustomerId);
            customers[customerIndex].is_checked = isChecked;
        
        this.setState({
            customers: customers
        });

    }

    onAllChecked = e => {
        const checked = e.target.checked;
        this.setState({
            all_checked: checked,
            customers: this.state.customers.map(customer => {
                customer.is_checked = checked
                return customer;
            })
        })
    }

    async generateReport (defaultPrint = false) {
        if(!checkFeatureInSubscription("reports")){
            return false;
        }
        try {
            this.setState({
                is_generating: true
            });
            const formData = new FormData(document.getElementById('dairy_report_form'));
            analytics.logEvent("downloadReport", {
                reportType: formData.get('report_type')
            });
            loader.show(this.props.t("text_loading"));
            const { startDate, endDate, customers } = this.state
            const dairyCustomersId = customers.filter(customer => customer.is_checked).map(customer => customer.dairy_customer_id);
            formData.append('start_date', startDate.format("YYYY-MM-DD"));
            formData.append('end_date', endDate.format("YYYY-MM-DD"));
            const reportType = formData.get('report_type');
            if(reportType === "customer") {
                formData.append('customers', JSON.stringify(dairyCustomersId));
            }
            const data = await request.post('/dairies/' + this.props.dairyId + `/reports`, {
                withAuth: true,
                body: formData,
                responseType: "text/html; charset=utf-8",
                version: "v2"
            });
            const fileName = `${(reportType == "customer" ? "Customers" : "Dairy")}_report_${startDate.format("YYYY_MM_DD")}__${endDate.format("YYYY_MM_DD")}.pdf`;
            if(typeof cordova !== "undefined") {
                await generatePDF(data, fileName);
            } else {
                this.setState({
                    reportFilename: fileName,
                    reportHtml: data
                }, async () => {
                    if(defaultPrint) {
                        await generatePDF(data, fileName);
                    }
                });
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                is_generating: false
            });
        }
    }

    downloadReport = () => {
        if(!checkFeatureInSubscription("reports")){
            return false;
        }
        generatePDF(this.state.reportHtml, this.state.reportFilename);
    }

    onDeviceChange = (deviceDetails) => {
        this.setState({
            ...deviceDetails
        });
    }
}

export default DairyReportController;