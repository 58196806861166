import moment from "moment";

export const getTenDaysStartEndDate = selectedDate => {
    const dateFormat = "YYYY-MM-DD";
    const selectedDay = selectedDate.format("DD");
    let startDate, endDate;
    const selectedDateCloned = selectedDate.clone();
    if(selectedDay.startsWith("0") || selectedDay === "10") {
        startDate = selectedDateCloned.format("YYYY-MM-01");
        endDate = selectedDateCloned.format("YYYY-MM-10");
    } else if(selectedDay.startsWith("1") || selectedDay === "20") {
        startDate = selectedDateCloned.format("YYYY-MM-11");
        endDate = selectedDateCloned.format("YYYY-MM-20");
    } else if(selectedDay.startsWith("2") || selectedDay.startsWith("3")) {
        startDate = selectedDateCloned.format("YYYY-MM-21");
        endDate = selectedDateCloned.endOf("month").format("YYYY-MM-DD");
    }
    return {
        startDate: moment(startDate, dateFormat),
        endDate: moment(endDate, dateFormat)
    };
};

export const getBillingStartEndDate = (billingCycle, selectedDate) => {
    const dateFormat = "YYYY-MM-DD";
    const selectedDay = selectedDate.format("DD");
    let startDate, endDate;
    const selectedDateCloned = selectedDate.clone();
    
    if(billingCycle === "monthly") {
        startDate = selectedDateCloned.startOf("month").format("YYYY-MM-DD");
        endDate = selectedDateCloned.endOf("month").format("YYYY-MM-DD");
    } else {
        if(selectedDay.startsWith("0") || selectedDay === "10") {
            startDate = selectedDateCloned.format("YYYY-MM-01");
            endDate = selectedDateCloned.format("YYYY-MM-10");
        } else if(selectedDay.startsWith("1") || selectedDay === "20") {
            startDate = selectedDateCloned.format("YYYY-MM-11");
            endDate = selectedDateCloned.format("YYYY-MM-20");
        } else if(selectedDay.startsWith("2") || selectedDay.startsWith("3")) {
            startDate = selectedDateCloned.format("YYYY-MM-21");
            endDate = selectedDateCloned.endOf("month").format("YYYY-MM-DD");
        }
    }

    return {
        startDate: moment(startDate, dateFormat),
        endDate: moment(endDate, dateFormat)
    };
};

export const get10DaysCycleDiff = (startDate) => {
    const { startDate: currentStartDate, endDate: currentEndDate } = getBillingStartEndDate("10 days", moment());
    if(!startDate.isBetween(currentStartDate, currentEndDate, undefined, "[]")) {
        const selectedDay = currentStartDate.format("DD");
        let lastStartDate, lastEndDate;
        if(selectedDay.startsWith("0") || selectedDay === "10") {
            const lastMonth = moment().subtract(1, "months");
            lastStartDate = lastMonth.format("YYYY-MM-21");
            lastEndDate = lastMonth.endOf("month").format("YYYY-MM-DD");
        } else if(selectedDay.startsWith("1") || selectedDay === "20") {
            lastStartDate = moment().format("YYYY-MM-01");
            lastEndDate = moment().format("YYYY-MM-10");
        } else if(selectedDay.startsWith("2") || selectedDay.startsWith("3")) {
            lastStartDate = moment().format("YYYY-MM-11");
            lastEndDate = moment().format("YYYY-MM-20");
        }
        if(lastStartDate && lastEndDate) {
            return startDate.isBetween(lastStartDate, lastEndDate, undefined, "[]");
        }
    }

    return false;
};