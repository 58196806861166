import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import withLanguage from "../../../../language/LanguageController";
import request from "../../../../library/request";
import * as Sentry from "@sentry/react";
import config from "../../../../../config";
import Spinner from "src/components/core/Spinner";

const AdvancePaymentDetail = ({
    t,
    dairyId,
    dairyCustomerId,
    startDate,
    endDate,
    isFeatureSubscribed,
    onAdvancePaymentTotal = (val) => {}
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const getPayment = useCallback(async () => {
        try {
            setIsLoading(true);
            let filterStr = encodeURI(`start_date=${startDate.format(config.db_date_format)}&end_date=${endDate.format(config.db_date_format)}`);
            const { status, data } = await request.get('/dairies/' + dairyId + `/accounts/advancePayment/${dairyCustomerId}?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                setRecords(data || []);
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    }, [dairyId, dairyCustomerId, startDate, endDate]);

    useEffect(() => {
        if(dairyCustomerId && startDate && endDate && isFeatureSubscribed) {
            getPayment();
        }
        if(!isFeatureSubscribed) {
            setIsLoading(false);
        }
    }, [getPayment, dairyCustomerId, startDate, endDate, isFeatureSubscribed]);

    useEffect(() => {
        if(records.length) {
            onAdvancePaymentTotal(records.reduce((total, record) => total + parseFloat(record.amount), 0));
        } else {
            onAdvancePaymentTotal("");
        }
    }, [records, onAdvancePaymentTotal]);

    if(isLoading) {
        return <div className="mt-2"><Spinner /></div>
    }

    return (
        <table className="table mb-0">
            <thead>
                <tr>
                    <th className="text-left min-width105">{t("text_date")}</th>
                    <th>{t("text_amount")}</th>
                    <th className='text-right'>{t("text_remark")}</th>
                </tr>
            </thead>
            <tbody>
                {records.map((record, key) => {
                    return (
                        <tr key={key}>
                            <td className="text-left">{moment(record.date).format('DD MMM, YY')}</td>
                            <td>{record.amount}</td>
                            <td className='text-right'>{record.remark}</td>
                        </tr>
                    );
                })}
                {!records.length ? (
                    <tr>
                        <td colSpan={3} className="text-center">
                            {t("text_no_data_available")}
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </table>
    );
};

AdvancePaymentDetail.defaultProps = {
    isFeatureSubscribed: true
};

export default withLanguage(AdvancePaymentDetail);