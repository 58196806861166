// Dairy Customers Constants
export const GET_CUSTOMER = "GET_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_ERROR = "FETCH_CUSTOMERS_ERROR";
export const FETCH_DAIRY_TODAY_TOTAL_SUCCESS = "FETCH_DAIRY_TODAY_TOTAL_SUCCESS";
export const FETCH_DAIRY_DAILY_TOTAL_SUCCESS="FETCH_DAIRY_DAILY_TOTAL_SUCCESS";
export const UPDATE_CUSTOMERS_RATELIST = "UPDATE_CUSTOMERS_RATELIST";
export const RESET_CUSTOMERS = "RESET_CUSTOMERS";

// Dairy constants
export const FETCH_DAIRY_REQUEST = "FETCH_DAIRY_REQUEST";
export const FETCH_DAIRY_SUCCESS = "FETCH_DAIRY_SUCCESS";
export const FETCH_DAIRY_FAILURE = "FETCH_DAIRY_FAILURE";
export const FETCH_KISAN_DAIRY_REQUEST = "FETCH_KISAN_DAIRY_REQUEST";
export const FETCH_KISAN_DAIRY_SUCCESS = "FETCH_KISAN_DAIRY_SUCCESS";
export const FETCH_KISAN_DAIRY_FAILURE = "FETCH_KISAN_DAIRY_FAILURE";
export const FETCH_DAIRIES_SUCCESS = "FETCH_DAIRIES_SUCCESS";
export const UPDATE_DAIRY = "UPDATE_DAIRY";
export const FETCH_DAIRY_PAYMENT_SUCCESS = "FETCH_DAIRY_PAYMENT_SUCCESS";
export const FETCH_DAIRY_PAYMENT_FAILED = "FETCH_DAIRY_PAYMENT_FAILED";
export const DAIRY_SELECTED_TOGGLE = "DAIRY_SELECTED_TOGGLE";
export const FETCH_DAIRY_PREFERENCE_SUCCESS = "FETCH_DAIRY_PREFERENCE_SUCCESS";
export const SELECT_DAIRY = "SELECT_DAIRY";

// User constants
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_LOGIN = "SET_LOGIN";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const ADDRESS_UPDATE_SUCCESS = "ADDRESS_UPDATE_SUCCESS";
export const UPDATE_PROFILE_PIC = "UPDATE_PROFILE_PIC";
export const UPDATE_AUTH_DETAIL = "UPDATE_AUTH_DETAIL";

// Subscriptions
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_FEATURES_SUCCESS = "FETCH_FEATURES_SUCCESS";
export const FETCH_ORDERS_BEGIN = "FETCH_ORDERS_BEGIN";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILED = "FETCH_ORDERS_FAILED";
export const FETCH_SUBSCRIPTION_REQUEST = "FETCH_SUBSCRIPTION_REQUEST";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";
export const SUBSCRIPTION_RESET = "SUBSCRIPTION_RESET";
export const DELETE_SUBSCRIPTION_ADDON_REQUEST = "DELETE_SUBSCRIPTION_ADDON_REQUEST";
export const DELETE_SUBSCRIPTION_ADDON_SUCCESS = "DELETE_SUBSCRIPTION_ADDON_SUCCESS";
export const DELETE_SUBSCRIPTION_ADDON_FAILURE = "DELETE_SUBSCRIPTION_ADDON_FAILURE";

// Referral
export const TOGGLE_REFERRAL_MODAL = "TOGGLE_REFERRAL_MODAL";


//Customer Preference

export const FETCH_CUSTOMER_PREFERENCE="FETCH_CUSTOMER_PREFERENCE";

// Other
export const FETCH_APP_CONFIGURATION_REQUEST = "FETCH_APP_CONFIGURATION_REQUEST";
export const FETCH_APP_CONFIGURATION_SUCCESS = "FETCH_APP_CONFIGURATION_SUCCESS";
export const FETCH_APP_CONFIGURATION_FAILURE = "FETCH_APP_CONFIGURATION_FAILURE";
export const TOGGLE_LANGUAGE_POPUP = "TOGGLE_LANGUAGE_POPUP";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const TOGGLE_PREMIUM_POPUP = "TOGGLE_PREMIUM_POPUP";
export const MILK_ENTRY_BY_CODE = "by_code";
export const MILK_ENTRY_BY_NAME = "by_name";
export const MILK_ENTRY_BY_BOTH = "by_both";
export const ORDER_ASC = "asc";
export const ORDER_DESC = "desc";


export const TOGGLE_SUBSCRIPTION_UPSALE_POPUP = "TOGGLE_SUBSCRIPTION_UPSALE_POPUP";
export const OTP_TIMEOUT = 60; //In seconds
export const DAIRY_CUSTOMER_CODE_DEBOUNCE = 300;
export const SLICK_SLIDER_BREAKPOINT = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
    }
];

export const PLAY_STOR_LINK="https://play.google.com/store/apps/details?id=in.liter.live";